import React, {Component} from 'react';

class AgencyAction extends Component {
    render(){
        return(
            <section className="action_area_three sec_pad">
                <div className="curved"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="action_content text-center">
                                <h2 className="f_600 f_size_30 l_height45 ">100% safe Delivery within USA</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AgencyAction;