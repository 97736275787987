import React from 'react';
import Reveal from 'react-reveal';
const AppFeatures = () => {
    return (
        <section className="app_featured_area_two" id="about">
            <div className="container">
                <div className="row app_feature_info">
                    <div className="col-lg-6">
                        <div className="app_img">
                            <div className="dot dot_one wow fadeIn" data-wow-delay="0.5s"><span className="dot1"></span><span className="dot2"></span></div>
                            <div className="dot dot_two wow fadeIn" data-wow-delay="0.8s"><span className="dot1"></span><span className="dot2"></span></div>
                            <div className="dot dot_three wow fadeIn" data-wow-delay="0.3s"><span className="dot1"></span><span className="dot2"></span></div>
                            <Reveal effect="fadeInLeft" duration={2500}><img className="text_bg one" src={require('../../img/home7/text_three.png')} alt="" /></Reveal>
                            <Reveal effect="fadeInDown" duration={2000}><img className="text_bg two" src={require('../../img/home7/text_one.png')} alt="" /></Reveal>
                            <Reveal effect="fadeInRight" duration={1500}><img className="text_bg three" src={require('../../img/home7/text_two.png')} alt="" /></Reveal>
                            <img className="wow fadeIn phone_img" src={require('../../img/home7/iPhoneX.png')} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 d-flex align-items-center">
                        <Reveal bottom cascade>
                            <div className="app_featured_content">
                            <div className="symbols-pulse active">
                    <div className="pulse-1"></div>
                    <div className="pulse-2"></div>
                    <div className="pulse-3"></div>
                    <div className="pulse-4"></div>
                    <div className="pulse-x"></div>
                </div>
                                <h2 className="f_p f_size_30 f_700 t_color3 l_height45 mb-30">WHY CHOOSE US<br /> </h2>
                                <p className="f_400">As a leader in global supply chain management, Bay Leauge Intermodal offers a wide range of international freight forwarding services from supply chain solution design & integration to full range of multimodal transport (road, rail, air and sea freight FCL/LCL), including our own LCL product from usa and logistics services such as warehousing, order preparation, supply and distribution centers. Bay Leauge Intermodal provides quality services to local and global key sector players, such as Healthcare, Aid & Relief, Automotive, Industrial Projects, Aerospace industries, Defense, Fashion & Retail and other General Cargo as well.</p>
                            </div>
                        </Reveal>

                    </div>
                </div>
            </div>
        </section>
    )
}
export default AppFeatures;
