import React from 'react';
import OnepageMenu from '../components/OnepageMenu';
import ReactPlayer from "react-player";
import AppFeatures from '../components/Features/AppFeatures';
import ServiceData from '../components/Service/ServiceData';
import PaymentService from '../components/Service/PaymentService';
import AppWork from '../components/Service/AppWork';
import StartupFeatures from '../components/Features/StartupFeatures';
import Stservice from '../components/Service/Sservice/StartupService';
import Testimonial from '../components/Testimonial/Testimonial';
import Cprogress from '../components/CircleProgressbar';
import Contacts from '../components/Contacts';
import AgencyAction from '../components/AgencyAction';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const HomeCRM = () => {
    return (
        <div className="body_wrapper">
            <OnepageMenu slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive" />
            <div className="main-video-wrapper" id='home'>
                <ReactPlayer url={require("../img/bannervideo.mp4")}
                    playing={true}
                    muted={true}
                    loop={true}
                    controls={false}
                    width="100%"
                    height="100%"
                />
                {/* <div className="heading-bay" id="Homee">
                    <h1 > Welcome to Bay League </h1>
                    <p> Your best Drayage Partner </p>
                </div> */}
            </div>
            <AppFeatures />
            <PaymentService ServiceData={ServiceData}/>
            <AppWork ServiceData={ServiceData} />
            <StartupFeatures />
            <Stservice />
            <Cprogress />
            <Testimonial tClass="testimonial_area_four sec_pad" FooterData={FooterData} />
            <Contacts/>
            <AgencyAction/>
            <Footer FooterData={FooterData} />
        </div>
    )
}
export default HomeCRM;